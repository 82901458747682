.login-page-container-confirm {
  min-height: 100%;
  min-width: 100%;
  display: flex;
  position: fixed;
  justify-content: center;
}

.confirm-base-div {
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}
.confirm-pop-up {
  background-image: url("/popup-background.png");
  background-size: contain;
  background-repeat: no-repeat;
  align-items: center;
  z-index: 99;
  max-width: 80vw;
    //   background-position-y: center;
      background-position-x: center;
    max-height: 95vh;
}
.confirm-pop-up-first-div {
  width: 100%;
  height: 100%;
}
.confirm-pop-up-content-div {
  margin-left: 10em;
  margin-top: 2em;
  display: flex;
  align-items: center;
}
.confirm-pop-up-response-image {
  height: 60px;
  width: 60px;
}
.lejart-az-ido-2 {
  height: 40px;
  width: 219px;
  color: #ffffff;
  font-family: Metropolis;
  font-size: 28px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 40px;
  margin-left: 20px;
}

.sajnos-tul-sokara-er-2 {
  height: 66px;
  width: 295px;
  color: #ffffff;
  font-family: Metropolis;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  margin-left: 7em;
  margin-top: 4em;
}

// kiskijelző
@media screen and (max-width: 500px) {
  .login-page-container-confirm {
    min-height: 100%;
    min-width: 100%;
    display: flex;
    position: fixed;
    justify-content: center;
  }

  .confirm-base-div {
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .confirm-pop-up {
    background-image: url("/popup-background.png");
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 99;
    max-width: 80vw;
    max-height: 95vh;
  }
  .confirm-pop-up-first-div {
    width: 100%;
    height: 100%;
  }
  .confirm-pop-up-content-div {
    margin-left: 20vw;
    margin-top: 4vh;
    display: flex;
    align-items: center;
  }
  .confirm-pop-up-response-image {
    height: 40px;
    width: 40px;
  }
  .lejart-az-ido-2 {
    font-size: 16px;
  }
  .sajnos-tul-sokara-er-2 {
  height: 10vh;
  width: 45vw;
  color: #FFFFFF;
  font-family: Metropolis;
  font-weight: 400;
  font-size: 11px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  margin-left: 25vw;
  margin-top: 4vh;
}
}
