@font-face {
  font-family: 'Metropolis';
  font-weight: 900;
  src: local('Metropolis-Black'), url("/fonts/Metropolis-Black.otf");
}
@font-face {
  font-family: 'Metropolis';
  font-weight: 400;
  src: local('Metropolis-regular'), url("/fonts/Metropolis-Regular.otf");
}
.on-the-following-int {
  height: 153px;
  width: 640px;
  color: #FFFFFF;
  font-family: Bariol;
  font-size: 24px;
  letter-spacing: 1.5px;
  line-height: 24px;
    background-color: rgba(54, 57, 74, 0.9);
    height: auto;
}
.kerlek-add-meg-az-u {
  height: 58px;
  width: 200px;
  color: #795A21;
  font-family: Metropolis;
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 29px;
  text-align: center;
  min-height: 100px;
  // margin-bottom: 100px;
}
.minimum-8-karakter {
  height: 36px;
  width: 460px;
  color: #795A21;
  font-family: Metropolis;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 40px;
}
.buttons-medium-button-with-ico {
  height: 65px !important;
  width: 221.32px  !important;
    height: 64.4px  !important;
  width: 220px  !important;
  border-radius: 20px  !important;
  background-color: #B48523 !important;
  border-color: #B48523 !important;
}
.megsem {
  height: 60px !important;
  width: 220px !important;
  color: #795A21 !important;
  font-family: Metropolis !important;
  font-size: 21px !important;
  font-weight: 900 !important;
  letter-spacing: 0 !important;
  line-height: 25px !important;
  text-align: center !important;
  border-color: #B48523 !important;
      border-radius: 20px;
    background-color: transparent !important;
}

.uj-jelszo {
 box-sizing: border-box !important;
  height: 54px!important;
  width: 460px!important;
  border: 3px solid #795A21!important;
  border-color: #B48523 !important;
  border-radius: 20px!important;
  background-color: transparent !important;
  input{
      color:#795A21 !important;
      font-weight: 900;
      background-color: transparent !important;
  }
  input::placeholder{
      color:#795A21 !important;
      font-weight: 900;
      background-color: transparent !important;
  }
}

.parent {
  position: relative;
  top: 40px;
  left: 0;
}
.parent1 {
display: flex;
    justify-content: space-between;
}
.image1 {
  position: relative;
  display: inline;
  width: 100%;
  height: 60vh;
      top: 0px;
    right: 0px;
  filter: drop-shadow(0 3mm 2mm rgba(43, 43, 43, 0.3));

}
.imageLogo {
  position: absolute;
z-index: 100;
  display: inline;
  width: 100%;
  top: 0;
  left: 4em;
  max-width: 10%;
  max-height: 50px;
  filter: drop-shadow(0 3mm 2mm rgba(43, 43, 43, 0.3));

}
.image2 {
  position: absolute;
  display:  flex;
  bottom: -5em;
  left: 2em;
  filter: drop-shadow(0 3mm 2mm rgba(43, 43, 43, 0.3));
}

.line-picture {
margin-left: 1em;
margin-bottom: 1em;
max-height: 125px;
}

.picturebaseDiv1 {
 display: inline !important;
 max-width: 70%;
}
.picturebaseDiv2 {
 display: inline;
 max-width: 75%;
}

.logoBaseDiv {
   display: inline;
 width: 25%;
}

.left-group-div {
    // position: relative;
    bottom: -10em;
    left: 0;
}
.ant-result-icon {
  margin-bottom: 0 !important;
}
.lejart-az-ido-2-confirm {

  width: 219px;
  color: #ffffff;
  font-family: Metropolis;
  font-size: 28px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 40px;
  margin-left: 20px;
}

@media screen and (max-width: 600px) {
  .kerlek-add-meg-az-u{
     height: 12px;
  width: 130px;
  color: #795A21;
  font-family: Metropolis;
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  // margin-bottom: 10px;
  min-height: 50px !important;
  }
  .megsem {
  height: 30px !important;
  width: 110px !important;
  color: #795A21 !important;
  font-family: Metropolis !important;
  font-size: 21px !important;
  font-weight: 900 !important;
  letter-spacing: 0 !important;
  line-height: 25px !important;
  text-align: center !important;
  border-color: #B48523 !important;
      border-radius: 20px;
    background-color: transparent !important;
}
.buttons-medium-button-with-ico {
  height: 30px !important;
  width: 110px  !important;
  border-radius: 20px  !important;
  background-color: #B48523 !important;
  border-color: #B48523 !important;
}
  .lejart-az-ido-2-confirm {
    font-size: 16px;
  }
.uj-jelszo {
 box-sizing: border-box !important;
  height: 54px!important;
  width: 80vw!important;
  border: 3px solid #795A21!important;
  border-color: #B48523 !important;
  border-radius: 20px!important;
  background-color: transparent !important;
  input{
      color:#795A21 !important;
      font-weight: 900;
      background-color: transparent !important;
  }
  input::placeholder{
      color:#795A21 !important;
      font-weight: 900;
      background-color: transparent !important;
  }
}
.minimum-8-karakter {
  height: 36px;
  width: 80vw;
  color: #795A21;
  font-family: Metropolis;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 40px;
}
}
.confirm-base-div-change-password {
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.confirm-pop-up-content-div-change {
  margin-left: 9em;
  margin-top: 7em;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 500px) {
  .kerlek-add-meg-az-u{
     height: 22px;
  width: 130px;
  color: #795A21;
  font-family: Metropolis;
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 29px;
  text-align: center;
  margin-bottom: 0;
  min-height: 100px !important;
  }
  .confirm-pop-up-content-div-change {
    margin-left: 20vw;
    margin-top: 7vh;
    display: flex;
    align-items: center;
}
}

