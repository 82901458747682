@font-face {
  font-family: 'Metropolis';
  font-weight: 900;
  src: local('Metropolis-Black'), url("/fonts/Metropolis-Black.otf");
}
@font-face {
  font-family: 'Metropolis';
  font-weight: 400;
  src: local('Metropolis-regular'), url("/fonts/Metropolis-Regular.otf");
}

.base {
     //background: linear-gradient(to top, #A37915 0%, #89CF9B 100%);
    background: #EFEBD4;
    opacity: 1;
    width: '100vw';
    height:  '100vh';
}
.group-16 {
  height: 530px;
  width: 340px;
  transform: rotate(360deg);
}
.popup {
  height: 362px;
  width: 460px;
}

.lejart-az-ido {
  height: 40px;
  width: 219px;
  color: #FFFFFF;
  font-family: Metropolis;
  font-size: 28px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 40px;
  margin-left: 20px;
}

.sajnos-tul-sokara-er {
  height: 66px;
  width: 295px;
  color: #FFFFFF;
  font-family: Metropolis;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  margin-left: 83px;
  margin-top: 60px;
}
@media screen and (max-width: 600px) {
  .sajnos-tul-sokara-er {
    width: unset;
    }
    .lejart-az-ido{
       font-size: 22px
    }
}